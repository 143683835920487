$kinomap: #FBCC23;
$black: #050404;
$gray: rgba(0, 0, 0, 0.6);

$slope1: rgba(255, 0, 0, .8);
$slope2: rgba(255, 192, .8);
$slope3: rgba(255, 255, .8);
$slope4: rgba(84, 130, 53, .8);
$slope5: rgba(0, 176, 240, .8);

$blue: rgb(51, 118, 170);
$green: rgb(79, 197, 98);
$yellow: rgb(255, 223, 66);
$orange: rgb(230, 149, 36);
$red: rgb(222, 60, 33); 

$primary: #FBCC23;
$secondary: #95C141;
$success: #35AA69;
$info: #008B7F;
$warning: #006978;
$danger: #2F4858;
