/* You can add global styles to this file, and also import other style files */
@import "src/variables.scss";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "~leaflet/dist/leaflet.css";

$theme-colors: (
    "kinomap": $kinomap,
    "kinograylight": #eee,
);

$theme-colors: (
    "kinomap": $kinomap,
    "kinogray": rgba(0, 0, 0, 0.6),
    "kinograylight": #eee,
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
);

@import "~bootstrap/scss/bootstrap";

.kinocard {
    -webkit-box-shadow: 0 3px 10px rgba(62, 85, 120, 0.045) !important;
    -moz-box-shadow: 0 3px 10px rgba(62, 85, 120, 0.045) !important;
    box-shadow: 0 3px 10px rgba(62, 85, 120, 0.045) !important;
    -webkit-border-radius: 0.5rem !important;
    -moz-border-radius: 0.5rem !important;
    border-radius: 0.5rem !important;
    border: none !important;
    cursor: pointer;
    &:hover {
        -webkit-box-shadow: 0 3px 10px rgba(62, 85, 120, 0.3) !important;
        -moz-box-shadow: 0 3px 10px rgba(62, 85, 120, 0.3) !important;
        box-shadow: 0 3px 10px rgba(62, 85, 120, 0.3) !important;
    }
}
.introjs-helperLayer {
    background: transparent !important;
    &:before {
        opacity: 0;
        content: "";
        position: fixed;
        width: inherit;
        height: inherit;
        border-radius: 0.5rem;
        box-shadow: 0 0 0 1000rem rgba(0, 0, 0, 0.7);
        opacity: 1;
    }
    &:after {
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        position: fixed;
        z-index: 9999;
    }
}
.introjs-overlay {
    opacity: 0 !important;
}

.newbtn {
    background: linear-gradient(to right, #fbcc23, #ffd755dd) !important;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
    &:active {
        transform: scale(0.95);
    }
    &:focus {
        outline: none;
    }
}